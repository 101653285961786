// extracted by mini-css-extract-plugin
export var alignLeft = "B_qD d_fp d_bG d_dv";
export var alignCenter = "B_bP d_fq d_bD d_dw";
export var alignRight = "B_qF d_fr d_bH d_dx";
export var alignColumnLeft = "B_sM d_fs d_bN d_dv";
export var alignColumnCenter = "B_sN d_ft d_bP d_dw";
export var alignColumnRight = "B_sP d_fv d_bQ d_dx";
export var milestonesContainer = "B_tX d_dW";
export var milestonesContainerFull = "B_tY d_dT";
export var milestonesSubtitle = "B_mf d_mf d_cs";
export var milestonesComponentWrapper = "B_mb d_mb d_cv";
export var compContentWrapper = "B_sS d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "B_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "B_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "B_tZ d_H";
export var milestonesBlockWrapper = "B_t0";
export var milestonesTextBlockWrapper = "B_t1";
export var milestonesComponentHeader = "B_md d_md d_w d_cr";
export var milestonesComponentParagraph = "B_mj d_mj d_w";
export var btnWrapper = "B_d2 d_bz d_ck";
export var btnWrapperCards = "B_sV d_bz d_cd";
export var imageWrapper = "B_qy d_cs d_bz";
export var Title1Small = "B_rq q_rq q_qW q_qX";
export var Title1Normal = "B_rr q_rr q_qW q_qY";
export var Title1Large = "B_rs q_rs q_qW q_qZ";
export var Title2Small = "B_rt q_rt q_qW q_q0";
export var Title2Normal = "B_rv q_rv q_qW q_q1";
export var Title2Large = "B_rw q_rw q_qW q_q2";
export var SubtitleSmall = "B_qd q_qd q_qW q_q6";
export var SubtitleNormal = "B_qf q_qf q_qW q_q7";
export var SubtitleLarge = "B_qg q_qg q_qW q_q8";
export var Subtitle2Small = "B_rB q_rB q_qW q_q9";
export var Subtitle2Normal = "B_rC q_rC q_qW q_rb";
export var Subtitle2Large = "B_rD q_rD q_qW q_rc";
export var BodySmall = "B_rF q_rF q_qW q_rd";
export var BodyNormal = "B_rG q_rG q_qW q_rf";
export var BodyLarge = "B_rH q_rH q_qW q_rg";
export var exceptionLineHeight = "B_t2 q_rn";