// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_sX d_fp d_bG d_dv";
export var alignLeft = "v_qD d_fp d_bG d_dv";
export var alignDiscCenter = "v_sY d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_sZ d_fr d_bH d_dx";
export var alignRight = "v_qF d_fr d_bH d_dx";
export var footerContainer = "v_s0 d_dW d_bW";
export var footerContainerFull = "v_s1 d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_s2 d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_s3 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_s4 d_bz d_bJ d_bN d_bL";
export var wide = "v_s5 d_cy";
export var right = "v_s6 d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_s7 d_bC d_bP d_bJ";
export var badgeContainer = "v_s8 d_bz d_bH d_bP";
export var badge = "v_s9";
export var padding = "v_tb d_c7";
export var end = "v_tc d_bH";
export var linkWrapper = "v_td d_dB";
export var link = "v_mC d_dB";
export var colWrapper = "v_tf d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_tg d_bz d_bJ";
export var media = "v_th d_bx d_dy";
export var itemRight = "v_tj";
export var itemLeft = "v_tk";
export var itemCenter = "v_tl";
export var exceptionWeight = "v_tm q_rm";