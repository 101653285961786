// extracted by mini-css-extract-plugin
export var tileContent = "y_tF d_w d_H d_Z";
export var teamTextLeft = "y_tG d_dv";
export var teamTextCenter = "y_tH d_dw";
export var teamTextRight = "y_tJ d_dx";
export var alignLeft = "y_qD d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "y_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "y_qF d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "y_tK d_dW";
export var teamContainerFull = "y_tL d_dT";
export var teamRowWrapper = "y_tM d_cc";
export var teamTileWrapper = "y_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "y_tN d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "y_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "y_j5 d_j5 d_Z";
export var teamHoverNoGutters = "y_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "y_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "y_tP d_j7";
export var teamInfoWrapperRound = "y_j7 d_j7";
export var teamInfoWrapper = "y_tQ d_j8 d_0";
export var teamInfoWrapperNoGutters = "y_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "y_jZ d_jZ";
export var teamImgWrapperAlt = "y_j0 d_j0";
export var teamImgWrapperNoGutters = "y_kb d_kb";
export var teamHeader = "y_tR d_cw";
export var teamHeaderAlt = "y_tS d_cw";
export var teamHeaderNoGutters = "y_tT d_cw d_cD";