// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_tw d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_tx d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_ty d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_tz d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_tB d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_tC d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_tD d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_rL q_rL";
export var heroExceptionNormal = "x_rM q_rM";
export var heroExceptionLarge = "x_rN q_rN";
export var Title1Small = "x_rq q_rq q_qW q_qX";
export var Title1Normal = "x_rr q_rr q_qW q_qY";
export var Title1Large = "x_rs q_rs q_qW q_qZ";
export var BodySmall = "x_rF q_rF q_qW q_rd";
export var BodyNormal = "x_rG q_rG q_qW q_rf";
export var BodyLarge = "x_rH q_rH q_qW q_rg";