// extracted by mini-css-extract-plugin
export var lbContainerOuter = "J_v6";
export var lbContainerInner = "J_v7";
export var movingForwards = "J_v8";
export var movingForwardsOther = "J_v9";
export var movingBackwards = "J_wb";
export var movingBackwardsOther = "J_wc";
export var lbImage = "J_wd";
export var lbOtherImage = "J_wf";
export var prevButton = "J_wg";
export var nextButton = "J_wh";
export var closing = "J_wj";
export var appear = "J_wk";