// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "C_t3 d_bD";
export var storyRowWrapper = "C_hx d_hx d_bK";
export var storyLeftWrapper = "C_t4 d_bz d_bP";
export var storyWrapperFull = "C_t5 d_cD";
export var storyWrapperFullLeft = "C_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "C_mv d_hy";
export var storyLeftWrapperCenter = "C_t6 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "C_t7 d_hF";
export var storyHeader = "C_t8 d_hD d_w d_cs";
export var storyHeaderCenter = "C_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "C_hB d_hB d_by d_dw";
export var storyBtnWrapper = "C_t9 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "C_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "C_qy d_fg d_Z";
export var imageWrapperFull = "C_vb d_w d_H d_bf d_Z";
export var SubtitleSmall = "C_qd q_qd q_qW q_q6";
export var SubtitleNormal = "C_qf q_qf q_qW q_q7";
export var SubtitleLarge = "C_qg q_qg q_qW q_q8";
export var textLeft = "C_dv";
export var textCenter = "C_dw";
export var textRight = "C_dx";