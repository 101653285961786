// extracted by mini-css-extract-plugin
export var alignLeft = "z_qD d_fp d_bG d_dv";
export var alignCenter = "z_bP d_fq d_bD d_dw";
export var alignRight = "z_qF d_fr d_bH d_dx";
export var menuContainer = "z_tV d_dW";
export var menuContainerFull = "z_tW d_dT";
export var menuMainHeader = "z_jC d_jC d_w d_c3";
export var menuComponentWrapper = "z_jG d_jG d_cv";
export var menuComponentWrapperDesign2 = "z_jH d_jH d_cy";
export var menuComponentText = "z_jD d_jD d_cn";
export var menuComponentTextDesign2 = "z_jF d_jF d_cs";
export var menuImageWrapperDesign2 = "z_jJ d_jJ d_w d_Z";